import axios from 'axios';

const apiUrl = import.meta.env.VITE_APP_BASEURL
// 创建 Axios 实例
const instance = axios.create({
    baseURL: apiUrl,
    timeout: 10000, // 请求超时时间
    headers: {
        'Content-Type': 'application/json',
    }
});

// 请求拦截器
instance.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么
        // 比如：添加 token 到请求头
        // config.headers.Authorization = `Bearer ${getToken()}`
        return config;
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    response => {
        // 对响应数据做点什么
        return response.data;
    },
    error => {
        // 对响应错误做点什么
        return Promise.reject(error);
    }
);

export default instance;

export const carousel = () => {
    let intervalId;
    const slides = document.getElementById('carousel-list');
    const prevBtn = document.getElementById('prevBtn');
    const nextBtn = document.getElementById('nextBtn');
    const indicators = document.querySelector('.indicators');
    const totalSlides = slides.querySelectorAll('li').length;
    let currentIndex = 0;

    // 创建指示器
    for (let i = 0; i < totalSlides; i++) {
        const indicator = document.createElement('div');
        indicator.classList.add('indicator');
        if (i === currentIndex) {
            indicator.classList.add('active');
        }
        indicator.addEventListener('click', () => {
            goToSlide(i);
        });
        indicators.appendChild(indicator);
    }

    // 设置轮播定时器
    function startCarousel() {
        intervalId = setInterval(() => {
            goToNextSlide();
        }, 3000);
    }

    // 开始轮播
    startCarousel();

    // 鼠标进入停止轮播
    document.querySelector('.carousel').addEventListener('mouseenter', () => {
        clearInterval(intervalId);
        prevBtn.style.display = 'block';
        nextBtn.style.display = 'block';
        
        // 禁用指示器点击
        document.querySelectorAll('.indicator').forEach(indicator => {
            indicator.style.pointerEvents = 'none';
        });
    });

    // 鼠标离开开始轮播
    document.querySelector('.carousel').addEventListener('mouseleave', () => {
        startCarousel();
        prevBtn.style.display = 'none';
        nextBtn.style.display = 'none';
        
        // 启用指示器点击
        document.querySelectorAll('.indicator').forEach(indicator => {
            indicator.style.pointerEvents = 'auto';
        });
    });

    // 切换到指定页面
    function goToSlide(index) {
        currentIndex = index;
        updateSlideOpacity();
        updateIndicators();
    }

    // 切换到下一页
    function goToNextSlide() {
        currentIndex = (currentIndex + 1) % totalSlides;
        updateSlideOpacity();
        updateIndicators();
    }

    // 更新幻灯片的透明度
    function updateSlideOpacity() {
        const allSlides = slides.querySelectorAll('li');
        allSlides.forEach((slide, index) => {
            if (index === currentIndex) {
                slide.style.opacity = 1;
            } else {
                slide.style.opacity = 0;
            }
        });
    }

    // 更新指示器状态
    function updateIndicators() {
        const allIndicators = document.querySelectorAll('.indicator');
        allIndicators.forEach((indicator, index) => {
            if (index === currentIndex) {
                indicator.classList.add('active');
            } else {
                indicator.classList.remove('active');
            }
        });
    }

    prevBtn.addEventListener('click', () => {
        currentIndex = (currentIndex - 1 + totalSlides) % totalSlides;
        updateSlideOpacity();
        updateIndicators();
    });

    nextBtn.addEventListener('click', () => {
        goToNextSlide();
    });
}

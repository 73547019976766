<!--
 * @Author: JianYangLuo
 * @Date: 2024-5-7 14:19:00
 * @LastEditors: JianYangLuo
 * @LastEditTime: 2024-5-7 14:19:00
 * @Description: 公告详情页
-->
<template>
    <div class="zxz_list">
        <div class="zxz_list_body">
            <div class="header">
                <el-page-header class="page-header" @back="goBack" :content="currentPosition"> </el-page-header>
            </div>
            <el-card shadow="never" style="min-height: 600px;">
                <div class="zxz_list_content">
                    <div class="title">{{ title }}</div>
                    <div class="content" v-html="content">
                    </div>
                    <div class="file">
                        <div class="file_List" v-if="fileList && fileList.length !== 0">
                            <div class="file_type">公告附件：</div>
                            <div class="file_item">
                                <el-button :icon="Download" @click="handleDownLoad(item)" v-for="item in fileList"
                                    :key="item" type="primary" link>{{ item.originName
                                        ? item.originName : '' }}</el-button>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="zxz_action_tool">
                    <el-button v-if="!hideJoinBtn" type="primary" @click="handleJoin">我要报名</el-button>
                </div>
            </el-card>
        </div>
    </div>
</template>
<script setup>
import { Download } from '@element-plus/icons-vue'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router';

import { selectPublicNoticeDetailAPI, selectPublicNoticeFileListDetailAPI, tenderFileDownLoadAPI } from '@/api/notice'
import { ElMessageBox } from 'element-plus'
import { saveAs } from 'file-saver';

import { BACK_URL } from '@/config/url.js'
const route = useRoute()
// const router = useRouter()
const currentPosition = ref()
const content = ref('') // 公告内容
const title = ref('') // 标题
const fileList = ref([]) // 文件列表
const backUrl = BACK_URL

const hideJoinBtn = ref(false)

// 报名跳转
const handleJoin = () => {
    ElMessageBox.confirm('请登录后在菜单-报名信息，下进行报名操作', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确认',
        type: "warning",
    }).then(() => {
        const adminUrl = import.meta.env.VITE_APP_ADMIN_BASEURL;
        window.open(adminUrl, 'admin')
    }).catch(() => {
        return false
    })
}

// 返回
const goBack = () => {
    // console.log(route)
    window.open(backUrl, '_self')
    // router.back('http://portal.keypower8888.com')
}

const handleDownLoad = async (file) => {
    try {
        const config = { responseType: "blob" }
        const res = await tenderFileDownLoadAPI(file.id, config)
        const type = file.originName.split('.')[1]
        let blob = new Blob([res], { type: `application/${type};charset=utf-8` });
        saveAs(blob, `${file.originName}`);
    } catch (error) {
        ElMessageBox.alert('请登录后下载公告附件！', '提示', { type: 'warning' }).then(() => {
        }).catch(() => { })
    }


    // ElMessageBox.confirm('请登录后下载公告附件！', '提示', {
    //     cancelButtonText: '取消',
    //     confirmButtonText: '确认',
    //     type: "warning",
    // }).then(() => {
    //     const adminUrl = import.meta.env.VITE_APP_ADMIN_BASEURL;
    //     window.open(adminUrl, 'admin')
    // }).catch(() => {
    //     return false
    // })
}

const compareDataTime = (regDeadLine) => {
    const targeTimeStamp = new Date(regDeadLine).getTime()
    const currentTimeStamp = new Date().getTime()
    return targeTimeStamp > currentTimeStamp ? true : false
}

onMounted(async () => {
    document.documentElement.scrollTop = 0 // 到达顶部
    const path = route.path
    const noticeId = route.query.noticeId
    const regDeadLine = route.query.regDeadLine

    if (!compareDataTime(regDeadLine)) {
        // 隐藏报名按钮
        hideJoinBtn.value = true
    }
    if (path == '/detail') {
        currentPosition.value = '公告详情'
    } else if (path == '/list') {
        currentPosition.value = '首页-招标/资格预审公告'
    }

    const res = await selectPublicNoticeDetailAPI(noticeId)
    if (res.result === 'SUCCESS') {
        content.value = res.data.content
        title.value = res.data.title
    } else {
        console.warn('未查询到数据')
    }

    const file = await selectPublicNoticeFileListDetailAPI(noticeId)
    if (file.result === 'SUCCESS') {
        fileList.value = file.data
        console.log(fileList.value)
    } else {
        console.warn('未查询到数据')
    }

})
</script>
<style lang="scss" scoped>
.zxz_list {
    font-size: 14px;

    // 展示区
    .zxz_list_body {
        min-height: 700px;

        // 位置提示
        .header {
            display: flex;
            align-items: center;
            padding: 12px;
            height: 60px;

            .page-header {
                // padding: 20px;
            }

            // 提示
            .tip {
                margin-right: 20px;
            }
        }

        // 表格内容
        .zxz_list_content {
            padding: 0 20px 20px 20px;

            .title {
                height: 48px;
                line-height: 48px;
                text-align: center;
                background-color: #f2f2f2;
                font-size: 22px;
                margin: 0 0 20px 0;
            }

            .content {
                // text-indent: 2em; // 缩进2个单位
                padding: 20px; // 内边距
                line-height: 2em; // 行高2个单位
                min-height: 500px; // 最小高度
                letter-spacing: 1px; // 字体间距
                word-spacing: 2px; // 单词间距
            }

            .file {
                // height: 30px;
                line-height: 30px;
                // background-color: pink;
                margin-top: 20px;
                border-bottom: 1px solid #f2f2f2;

                .file_column {
                    border-bottom: 1px solid #f2f2f2;
                }

                .file_List {
                    margin-top: 10px;
                    height: 30px;
                    display: flex;
                    border: 1px solid #f2f2f2;

                    .file_type {
                        text-align: right;
                        width: 150px;
                        background-color: #f2f2f2;
                    }

                    .file_item {
                        margin-left: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-evenly;
                    }
                }
            }
        }

        .zxz_action_tool {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }


    }


}
</style>

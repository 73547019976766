<!--
 * @Author: JianYangLuo
 * @Date: 2024-5-7 14:19:00
 * @LastEditors: JianYangLuo
 * @LastEditTime: 2024-5-7 14:19:00
 * @Description: 公告列表
-->
<template>
    <div class="zxz_list">
        <div class="zxz_list_body">
            <div class="header">
                <el-page-header class="page-header" @back="goBack" :content="content"> </el-page-header>
            </div>

            <div class="zxz_list_content">
                <el-card shadow="never">
                    <el-table v-loading="loading" :data="data" style="font-size: 14px;">
                        <el-table-column label="公告标题" prop="title">
                            <template #default="scope">
                                <el-button type="primary" link @click="table_show(scope.row)">{{ scope.row.title
                                    }}</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="发布时间" prop="publicTime" width="200">
                            <template #default="scope">
                                {{ scope.row.publicTime }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" prop="status" width="100">

                            <template #default="scope">
                                <el-button :disabled="scope.row.status == '10'" link>{{ scope.row.status == '10' ? '已截止'
                                    : '报名'
                                    }}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="zxz_list_pagination">
                        <el-pagination v-show="total > 1" @current-change="handlePageChange" :small="true" background
                            layout="prev, pager, next" :page-count="total" />
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router';

import { selectListPublicNoticeAPI } from '@/api/notice'
import { parseDateTime } from '@/utils/time'
import { BACK_URL } from '@/config/url.js'

const route = useRoute()
const router = useRouter()


const data = ref([]) // 表格数据
const total = ref() // 总页数
const loading = ref(false) // 加载状态
const content = ref("")
const backUrl = BACK_URL

const handlePageChange = (pageNo) => {
    // 当前页码发生变化，重新获取数据
    getPublicNoticeList(pageNo)
}

// 返回
const goBack = () => {
    window.open(backUrl, '_self')
}

// 获取列表数据
const getPublicNoticeList = async (pageNo = 1, pageSize = 10) => {
    loading.value = true
    const nature = route.query.nature
    const type = route.query.type
    if (type == 'A01,B01') {
        content.value = '招标/资格预审公告'
    } else if (type == 'A02') {
        content.value = '中标/资格预审结果公告'
    } else if (type == 'C01') {
        content.value = '其他公告'
    } else if (!type) { content.value = '更正公告' }

    console.log(nature, type)
    console.log(content.value)
    const params = {
        types: type || '',
        searchWord: '',
        nature,
        pageNo,
        pageSize
    }
    const res = await selectListPublicNoticeAPI(params)
    if (res.result === 'SUCCESS') {
        loading.value = false
        data.value = res.data.rows
        total.value = res.data.totalPage
    } else {
        data.value = []
        total.value = 0
    }

}

const table_show = (row) => {
    router.push(`/detail?noticeId=${row.id}&regDeadLine=${row.regDeadLine}`)
}
onMounted(() => {
    getPublicNoticeList()
})

// 监听 route.query 的变化，重新获取数据
watch(() => route.query, () => {
    getPublicNoticeList()
})
</script>

<style lang="scss" scoped>
.zxz_list {
    font-size: 14px;

    // 展示区
    &_body {
        height: 600px;

        // 位置提示
        .header {
            display: flex;
            align-items: center;
            padding: 12px;
            height: 60px;


            // 提示
            .tip {
                margin-right: 20px;
            }
        }

        // 表格内容
        .zxz_list_content {
            padding: 0 20px 20px 20px;

            // 分页
            .zxz_list_pagination {
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }


    }


}
</style>

<!--
 * @Author: JianYangLuo
 * @Date: 2024-5-7 14:19:00
 * @LastEditors: JianYangLuo
 * @LastEditTime: 2024-5-7 14:19:00
 * @Description: 表格组件
-->
<template>
    <div class="zxz_table">
        <div class="zxz_zgys">
            <div class="zxz_zgys_title">
                <div class="zxz_zgys_title_lf">{{ title }}</div>
                <span @click="handleRedirect(url)" class="zxz_zgys_title_rt">更多>></span>
            </div>
            <div class="zxz_zgys_table pd_lf_15">
                <el-table size="small" :data="tableData" style="font-size: 14px; height: 254px;">
                    <slot></slot>
                    <template #empty>
                        <div>
                            <el-empty :image-size="50" description="暂无公告"></el-empty>
                        </div>
                    </template>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
const router = useRouter()

defineProps({
    tableData: {
        type: Array,
        default: []
    },
    title: {
        type: String,
        default: ''
    },
    url: {
        type: String,
        default: ''
    }
})

const handleRedirect = (url) => {
    router.push(url)
}
</script>

<style scoped></style>

<template>
  <div id="app">
    <!-- 顶部 -->
    <div class="zxz_header">
      <div class="w" style="padding-top: 15px;">
        <img style="height: 64px; width: 404px;" src="/logo.png"></img>
      </div>
    </div>

    <!-- 导航栏-->
    <div class="zxz_header_bg" ref="top">
      <div class="zxz_navbar w pd_120">
        <a v-for="item in url" :key="item" :class="{ active: active === item.active }" :href="item.url">{{ item.name
          }}</a>
        <!-- <router-link v-for="item in url" :key="item.name" :to="item.url" :class="{ active: active === item.active }">
          {{ item.name }}
        </router-link> -->
      </div>
    </div>

    <div class="zxz_body w pd_120" style="padding-bottom: 100px;">
      <router-view></router-view>
    </div>

    <!-- 底部 -->
    <div class="zxz_footer">
      <div class="w">版权所有：中南大学湘雅二医院 备案号：<a style="color:var(--el-color-white)"
          href="https://beian.miit.gov.cn/">湘ICP备2024077070号</a>
        地址：中国-湖南-长沙市人民中路139号 技术支持: 湖南医鸽精采科技有限公司</div>
    </div>

    <div class="backBtn" v-show="showGoTop" @click="goToTop">顶部</div>
  </div>
</template>

<script setup>
import { onMounted, ref, } from "vue";
import { useRouter } from "vue-router";

import { NAVIGATOR_URL, PRIMARY_COLOR } from '@/config/url.js'
import colorTool from '@/utils/color.js'
const active = ref(0)
const router = useRouter()
const url = NAVIGATOR_URL

const top = ref(null)

const showGoTop = ref(false)
const handleScroll = () => {
  let scrolltop = document.documentElement.scrollTop; // 获取当前页面的滚动条纵坐标位置 
  if (scrolltop > top.value.offsetTop) {  // 菜单距离顶部的高度
    showGoTop.value = true // 超过就显示出来
  } else {
    showGoTop.value = false // 不超过还藏起来
  }
}


const goToTop = () => {
  let scrolltop = document.documentElement.scrollTop; // 获取当前页面的滚动条纵坐标位置 
  // 定时器平滑滚动
  const time = setInterval(() => {
    document.documentElement.scrollTop = scrolltop -= 40;
    if (scrolltop <= 0) {
      clearInterval(time);
    }
  }, 10);
}

const setPrimaryColor = () => {
  const app_color = PRIMARY_COLOR
  if (app_color) {
    document.documentElement.style.setProperty('--el-color-primary', app_color);
    for (let i = 1; i <= 9; i++) {
      document.documentElement.style.setProperty(`--el-color-primary-light-${i}`, colorTool.lighten(app_color, i / 10));
    }
    for (let i = 1; i <= 9; i++) {
      document.documentElement.style.setProperty(`--el-color-primary-dark-${i}`, colorTool.darken(app_color, i / 10));
    }
  }
}

const handleNavbarRedirect = (url) => {
  console.log(url)
  router.push(url)
}

onMounted(() => {
  window.addEventListener("scroll", handleScroll, true)
  setPrimaryColor()
})

</script>

<style lang="scss">
@import '@/styles/index.scss';
@import '@/styles/carousel.scss';

.backBtn {
  position: fixed;
  bottom: 200px;
  right: 200px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: var(--el-color-primary);
  color: #fff;
  box-shadow: 0px 2px 12px 0 #a0cfff;
  cursor: pointer;
  font-size: 15px;
  text-align: center;
  line-height: 50px;
  transition: all 0.3s;
}

.backBtn:hover {
  box-shadow: 0px 2px 12px 0 rgba(0, 0, 0, .3);
}
</style>

// 湘雅二医院门户 配置信息
// 登录地址
export const MANAGE_LOGIN_URL = 'https://www.egmdc.com/#/login';
// 注册地址
export const MANAGE_REGISTER_URL = 'https://www.egmdc.com/#/user_register';
// 返回地址
export const BACK_URL = 'https://portal.egmdc.com';
// 主题色
export const PRIMARY_COLOR = '#43AEA6';
// 首页导航栏
export const NAVIGATOR_URL = [
  {
    name: "首页",
    url: "https://portal.egmdc.com",
    active: 1
  },
  {
    name: "招标/资格预审公告",
    url: "https://portal.egmdc.com/#/list?nature=1&type=A01,B01",
    active: 2
  },
  {
    name: "更正公告",
    url: "https://portal.egmdc.com/#/list?nature=2",
    active: 3

  },
  {
    name: "中标/资格预审结果公告",
    url: "https://portal.egmdc.com/#/list?nature=1&type=A02",
    active: 4

  },
  {
    name: "其他公告",
    url: "https://portal.egmdc.com/#/list?nature=1&type=C01",
    active: 5
  },
  // {
  //   name: "使用指南",
  //   url: "/list?natrue=9&type=C01",
  //   active: 6
  // }
]


import { createRouter, createWebHashHistory } from 'vue-router';
// , createWebHistory,
import Home from '@/views/home/index.vue'
import List from '@/views/list/index.vue'
import Detail from '@/views/detail/index.vue'
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/list',
        name: 'List',
        component: List,
        beforeEnter: (to, from, next) => {
            const nature = to.query.nature;
            if (nature && /^\d+$/.test(nature)) {
                next();
            } else {
                next({ name: 'Home' });
            }
        }
    },
    {
        path: '/detail',
        name: 'detail',
        component: Detail
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});


export default router;

import instance from "@/utils/request"

// 已发布公告列表
export const selectListPublicNoticeAPI = (params) => {
    return instance.get('/notice/listPublicNotice', { params })
}

// 已发布公告详情
export const selectPublicNoticeDetailAPI = (noticeId) => {
    return instance.get('/notice/publicDetail', { params: { noticeId } })
}

// 已发布公告列表
export const selectPublicNoticeFileListDetailAPI = (noticeId) => {
    return instance.get('/notice/annexPubList', { params: { noticeId } })
}

// 附件下载
export const tenderFileDownLoadAPI = (fileId, config = {}) => {
    return instance.get('/publicService/getTenderFile', { params: { fileId }, ...config })
}
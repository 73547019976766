<!--
 * @Author: JianYangLuo
 * @Date: 2024-5-7 14:19:00
 * @LastEditors: JianYangLuo
 * @LastEditTime: 2024-5-7 14:19:00
 * @Description: 首页
-->

<template>
    <div class="zxz_table flex">
        <!-- 轮播图 -->
        <div class="zxz_carousel">
            <div class="carousel">
                <ul id="carousel-list">
                    <li v-for="item in srcList" :key="item"><img style="height: 100%; width: 100%;" fit="contain"
                            :src="item.src" alt=""></li>
                </ul>
                <div class="prev" id="prevBtn">&#10094;</div>
                <div class="next" id="nextBtn">&#10095;</div>
                <div class="indicators"></div>
            </div>
        </div>

        <!-- 登录指南 -->
        <div class="zxz_login">
            <div class="zxz_login_title">用户登录</div>
            <div class="zxz_login_user_login">
                <div class="icon">
                    <div class="icon_login"></div>
                    <a style="color: var(--el-color-primary)" :href="loginUrl">供应商登录</a>
                </div>
                <div class="icon">
                    <div class="icon_register"></div>
                    <a style="color:var(--el-color-primary);" :href="registerUrl">供应商注册</a>
                </div>
            </div>
        </div>
    </div>

    <!-- 招标公告/资格预审公告 -->
    <NoticeTable v-loading="loading" :title="noticeList.t1.title" :tableData="preData" :url="noticeList.t1.url">
        <el-table-column label="公告标题" prop="title">
            <template #default="scope">
                <el-button type="primary" link @click="checkDetail(scope.row)">{{ scope.row.title
                    }}</el-button>
            </template>
        </el-table-column>
        <el-table-column label="发布时间" prop="publicTime" width="200">
            <template #default="scope">
                {{ scope.row.publicTime }}
            </template>
        </el-table-column>
        <el-table-column label="操作" prop="status" width="100">
            <template #default="scope">
                <el-button v-if="!compareDataTime(scope.row)" type="info" disabled link>已截止</el-button>
                <el-button v-else @click="handleToLogin" type="primary" link>报名</el-button>
            </template>
        </el-table-column>
    </NoticeTable>

    <!-- 更正公告 -->
    <NoticeTable v-loading="loading" :title="noticeList.t2.title" :tableData="ntUpdateData" :url="noticeList.t2.url">
        <el-table-column label="公告标题" prop="title">

            <template #default="scope">
                <el-button type="primary" link @click="checkDetail(scope.row)">{{ scope.row.title
                    }}</el-button>
            </template>
        </el-table-column>
        <el-table-column label="发布时间" prop="publicTime" width="200">

            <template #default="scope">
                {{ scope.row.publicTime }}
            </template>
        </el-table-column>
        <el-table-column label="" width="100">
            <!-- <template #default="scope">
                <el-button :disabled="scope.row.status == '10'" link>{{ scope.row.status == '10' ? '已截止' : '报名'
                    }}</el-button>
            </template> -->
        </el-table-column>
    </NoticeTable>


    <!-- 中标/资格预审结果公告 -->
    <NoticeTable v-loading="loading" :title="noticeList.t3.title" :tableData="resultData" :url="noticeList.t3.url">
        <el-table-column label="公告标题" prop="title">

            <template #default="scope">
                <el-button type="primary" link @click="checkDetail(scope.row)">{{ scope.row.title
                    }}</el-button>
            </template>
        </el-table-column>
        <el-table-column label="发布时间" prop="publicTime" width="200">

            <template #default="scope">
                {{ scope.row.publicTime }}
            </template>
        </el-table-column>
        <el-table-column width="100" />
    </NoticeTable>


    <!-- 其他公告 -->
    <NoticeTable v-loading="loading" :title="noticeList.t4.title" :tableData="otherData" :url="noticeList.t4.url">
        <el-table-column label="公告标题" prop="title">

            <template #default="scope">
                <el-button type="primary" link @click="checkDetail(scope.row)">{{ scope.row.title
                    }}</el-button>
            </template>
        </el-table-column>
        <el-table-column label="发布时间" prop="publicTime" width="200">

            <template #default="scope">
                {{ scope.row.publicTime }}
            </template>
        </el-table-column>
        <el-table-column width="100" />
    </NoticeTable>


    <!-- 资格预审公告列表 -->
    <!-- <div class="zxz_table">
        <div class="zxz_zgys">
            <div class="zxz_zgys_title">
                <div class="zxz_zgys_title_lf">招标/资格预审公告</div>
                <a href="/list?nature=1&type=A01,A02" class="zxz_zgys_title_rt">更多>></a>
            </div>
            <div class="zxz_zgys_table pd_15">
                <el-table size="small" :data="preData" style="font-size: 14px;">
                    <el-table-column label=" 公告标题" prop="title">
                        <template #default="scope">
                            <el-button type="primary" link @click="checkDetail(scope.row)">{{ scope.row.title
                                }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="发布时间" prop="publicTime" width="200">

                        <template #default="scope">
                             {{ parseDateTime(scope.row.publicTime)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" prop="status" width="100">

                        <template #default="scope">
                            <el-button :disabled="scope.row.status == '10'" link>{{ scope.row.status == '10' ? '已截止' :
                    '报名'
                                }}</el-button>
                        </template>
                    </el-table-column>

                    <template #empty>
                        <div>
                            <el-empty :image-size="50" description="暂无公告"></el-empty>
                        </div>
                    </template>
                </el-table>
            </div>
        </div>
    </div> -->

    <!-- 更正公告 -->
    <!-- <div class="zxz_table">
        <div class="zxz_zgys">
            <div class="zxz_zgys_title">
                <div class="zxz_zgys_title_lf">更正公告</div>
                <a href="/list?nature=2" class="zxz_zgys_title_rt">更多>></a>
            </div>
            <div class="zxz_zgys_table pd_15">
                <el-table size="small" :data="ntUpdateData" style="font-size: 14px;">
                    <el-table-column label=" 公告标题" prop="title">

                        <template #default="scope">
                            <el-button type="primary" link @click="checkDetail(scope.row)">{{ scope.row.title
                                }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="发布时间" prop="publicTime" width="200">

                        <template #default="scope">
                             {{ parseDateTime(scope.row.publicTime)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="" width="100">
                        <template #default="scope">
                            <el-button :disabled="scope.row.status == '10'" link>{{ scope.row.status == '10' ? '已截止' : '报名'
                            }}</el-button>
                        </template>
                    </el-table-column>

                    <template #empty>
                        <div>
                            <el-empty :image-size="50" description="暂无公告"></el-empty>
                        </div>
                    </template>
                </el-table>
            </div>
        </div>
    </div> -->

    <!-- 中标/资格预审结果公告 -->
    <!-- <div class="zxz_table">
        <div class="zxz_zgys">
            <div class="zxz_zgys_title">
                <div class="zxz_zgys_title_lf">中标/资格预审结果公告</div>
                <a href="/list" class="zxz_zgys_title_rt">更多>></a>
            </div>
            <div class="zxz_zgys_table pd_15">
                <el-table size="small" :data="otherData" style="font-size: 14px;">
                    <el-table-column label=" 公告标题" prop="title">

                        <template #default="scope">
                            <el-button type="primary" link @click="checkDetail(scope.row)">{{ scope.row.title
                                }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="发布时间" prop="publicTime" width="200">

                        <template #default="scope">
                             {{ parseDateTime(scope.row.publicTime)}}
                        </template>
                    </el-table-column>
                    <el-table-column width="100" />

                    <template #empty>
                        <div>
                            <el-empty :image-size="50" description="暂无公告"></el-empty>
                        </div>
                    </template>
                </el-table>
            </div>
        </div>
    </div> -->

    <!-- 其他公告 -->
    <!-- <div class="zxz_table">
        <div class="zxz_zgys">
            <div class="zxz_zgys_title">
                <div class="zxz_zgys_title_lf">其他公告</div>
                <a href="/list?nature=9" class="zxz_zgys_title_rt">更多>></a>
            </div>
            <div class="zxz_zgys_table pd_15">
                <el-table size="small" :data="otherData" style="font-size: 14px;">
                    <el-table-column label=" 公告标题" prop="title">

                        <template #default="scope">
                            <el-button type="primary" link @click="checkDetail(scope.row)">{{ scope.row.title
                                }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="发布时间" prop="publicTime" width="200" />
                    <el-table-column width="100" />

                    <template #empty>
                        <div>
                            <el-empty :image-size="50" description="暂无公告"></el-empty>
                        </div>
                    </template>
                </el-table>
            </div>
        </div>
    </div> -->
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router';

import { selectListPublicNoticeAPI } from '@/api/notice'
import NoticeTable from './components/NoticeTable.vue' // 表格

import { carousel } from '@/utils/carousel'
// import { parseDateTime } from '@/utils/time'
import { MANAGE_LOGIN_URL, MANAGE_REGISTER_URL } from '@/config/url.js'
const router = useRouter()

const loading = ref(false) // 表格加载状态
const preData = ref([]) //招标/资格预审公告
const ntUpdateData = ref([]) // 更正公告
const otherData = ref([]) //其他公告
const resultData = ref([]) // 结果公告

const loginUrl = MANAGE_LOGIN_URL
const registerUrl = MANAGE_REGISTER_URL

const noticeList = {
    t1: { url: '/list?nature=1&type=A01,B01', title: '招标/资格预审公告' },
    t2: { url: '/list?nature=2', title: '更正公告' },
    t3: { url: '/list?nature=1&type=A02', title: '中标/资格预审结果公告' },
    t4: { url: '/list?nature=1&type=C01', title: '其他公告' },
}

const srcList = [
    { src: '/img/banner1.jpg' },
    { src: '/img/banner2.jpg' },
    { src: '/img/banner3.jpg' },
    { src: '/img/banner4.jpg' },
    { src: '/img/banner5.jpg' },
]

// 获取公告信息
const getPublicNoticeList = async () => {
    try {
        const updateParams = {
            types: '',
            searchWord: '',
            nature: '2',
        };
        const tdOrPreParams = {
            types: 'A01,B01',
            searchWord: '',
            nature: '1',
        };
        const resultParams = {
            types: 'A02',
            searchWord: '',
            nature: '1',
        };
        const otherParams = {
            types: 'C01',
            searchWord: '',
            nature: '1',
        };

        // 并行发起所有API调用
        const [res1, res2, res3, res4] = await Promise.all([
            selectListPublicNoticeAPI(updateParams),
            selectListPublicNoticeAPI(tdOrPreParams),
            selectListPublicNoticeAPI(resultParams),
            selectListPublicNoticeAPI(otherParams)
        ]);

        const show1 = ShowLimitForData(res1.data.rows)//更正公告
        const show2 = ShowLimitForData(res2.data.rows)//招标/资格预审公告
        const show3 = ShowLimitForData(res3.data.rows)//其他公告
        const show4 = ShowLimitForData(res4.data.rows)//结果公告
        // 处理并显示结果
        ntUpdateData.value = show1; // 更正公告
        preData.value = show2; // 招标/资格预审公告
        resultData.value = show3; // 结果公告
        otherData.value = show4; // 其他公告

    } catch (error) {
        console.error('获取公告列表时出错：', error);
        // 处理错误情况
        ntUpdateData.value = [];
        preData.value = [];
        resultData.value = [];
        otherData.value = [];
    }
};



const ShowLimitForData = (arr, limit = 6) => {
    if (Array.isArray(arr)) {
        return arr.slice(0, limit)
    }
}

const checkDetail = (row) => {
    console.log(row.regDeadLine)
    router.push({
        path: "/detail",
        query: {
            noticeId: row.id,
            regDeadLine: row.regDeadLine
        }
    })
}

const compareDataTime = (row) => {
    // console.log('截止时间:', row)
    // console.log('当前时间：', new Date().getTime())
    const targeTimeStamp = new Date(row.regDeadLine).getTime()
    const currentTimeStamp = new Date().getTime()
    return targeTimeStamp > currentTimeStamp ? true : false
}

const handleToLogin = () => {
    const redirectUrl = import.meta.env.VITE_APP_ADMIN_BASEURL
    window.open(redirectUrl)
}

onMounted(() => {
    loading.value = true
    getPublicNoticeList()
    carousel()
    loading.value = false

})

</script>

<style lang="scss" scoped>
:deep(.el-empty) {
    margin-top: 20px;
    --el-empty-padding: 0;
    --el-empty-bottom-margin-top: 0;
}
</style>